import YoutubeEmbed from '../YoutubeEmbed';

const HeroArea = () => {
  return (
    <section id="hero-area" className="header-area header-eight">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="header-content">
              <h1>
                Welcome to the Dixre community, where dreams become reality.
              </h1>
              <p>
                Join us for insightful sessions packed with valuable and
                enriching discussions. Engage with tech industry experts,
                network with like-minded people, and experience an unforgettable
                journey of discovery. We eagerly await your participation!
              </p>
              <div className="button">
                <a href="#get-started" className="btn primary-btn">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="header-image">
              <YoutubeEmbed embedId="u7Iw-uFo41M" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroArea;
