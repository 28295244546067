import React from "react";
import { FieldProps } from "formik";
import { InputContainer, ErrorMessage } from "./default.styled";

interface CustomInputProps {
  field: FieldProps["field"];
  form: FieldProps["form"];
  placeholder?: string;
  type?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  field,
  form: { touched, errors },
  placeholder,
  type = "text",
  ...props
}) => {
  const InputElement = type === "textarea" ? "textarea" : "input";

  return (
    <InputContainer className="custom-input">
      <InputElement {...field} {...props} placeholder={placeholder} />
      {touched[field.name] &&
        errors[field.name] &&
        typeof errors[field.name] === "string" && (
          <ErrorMessage className="error-message">
            {errors[field.name] as string}
          </ErrorMessage>
        )}
    </InputContainer>
  );
};

export default CustomInput;
