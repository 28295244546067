export const InstructorProfiles = [
  {
    id: 1,
    imageSrc: "img/chatto.jpg",
    title: "Senior Software Engineer, Founder & CEO at Dixre Ltd",
    fullName: "Samaila Chatto Bashir",
    bio: `Samaila Chatto Bashir is a dedicated Software Developer with over 8 years of experience in PHP, JavaScript, and Python, specializing in modern frameworks such as Laravel, React / Redux, Vue JS / Vuex and others. Proficient in employing state-of-the-art tools and adhering to industry
        best practices. He is spearheading Dixre's journey towards building a reputation for Information Technology in Africa.`,
    portfolio: [
      "Senior Software Engineer, Crowdbotics – California, United States.",
      "Lead Software Developer, JobMatchHub – Lagos, Nigeria.",
      "Lead Software Developer, Lexington Technologies - Kaduna, Nigeria.",
    ],
    socialProfiles: [
      {
        platform: "LinkedIn",
        link: "https://www.linkedin.com/in/samaila-bashir/",
        isVisible: true,
      },
      {
        platform: "Facebook",
        link: "https://www.facebook.com/samaila.bashir",
        isVisible: true,
      },
      {
        platform: "Twitter",
        link: "https://twitter.com/@dixres_chatto",
        isVisible: true,
      },
    ],
  },
  {
    id: 2,
    imageSrc: "img/solomon.jpg",
    title: "Senior Software Engineer, Co-Founder & CTO at Dixre Ltd",
    fullName: "Solomon Yunana",
    bio: `Solomon Yunana is a Full Stack Engineer, with over 7 years of experience in designing, developing, and deploying web applications. Proficient in both front-end and back-end technologies, he maintains a strong focus on crafting efficient and user-friendly solutions. He demonstrates prowess in collaborating with cross-functional teams to deliver high-quality software products.`,
    portfolio: [
      "Senior Full Stack Engineer, Red Acre – Malta.",
      "Senior Full Stack Engineer, Symphony - Netherlands.",
      "Senior Full Stack Developer, Lexington Technologies – Kaduna, Nigeria.",
    ],
    socialProfiles: [
      {
        platform: "LinkedIn",
        link: "https://www.linkedin.com/in/solomon-yunana/",
        isVisible: true,
      },
      {
        platform: "Facebook",
        link: "",
        isVisible: false,
      },
      {
        platform: "Twitter",
        link: "",
        isVisible: false,
      },
    ],
  },
];
