import InstructorPorfolio from "../Portfolio";
import SocialProfiles from "../SocialProfiles";

interface IProfile {
  id: number;
  imageSrc: string;
  title: string;
  fullName: string;
  bio: string;
  portfolio: string[];
  socialProfiles: { platform: string; link: string; isVisible: boolean }[];
}

interface IInstructorProfileProps {
  profile: IProfile;
}

const InstructorProfile: React.FC<IInstructorProfileProps> = (
  props: IInstructorProfileProps
): JSX.Element => {
  const { id, imageSrc, title, fullName, bio, portfolio, socialProfiles } =
    props.profile;

  const firstName = fullName.split(" ")[0];
  const isImageOnLeft = id % 2 === 0;

  return (
    <section
      className="about-area about-five"
      // Temporal fix for ID
      id={`${firstName === "Solomon" ? "solomon" : "chatto"}`}
    >
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-lg-6 col-12 ${isImageOnLeft ? "order-lg-1" : ""}`}
          >
            <div className="about-image-five">
              <img src={imageSrc} alt="about" />
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="about-five-content">
              <h6 className="small-title text-lg">{title}</h6>
              <h2 className="main-title fw-bold">{fullName}</h2>
              <div className="about-five-tab">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-who-tab"
                      data-bs-toggle="tab"
                      data-bs-target={`#nav-who-${id}`}
                      type="button"
                      role="tab"
                      aria-controls="nav-who"
                      aria-selected="true"
                    >
                      About {firstName}
                    </button>
                    <button
                      className="nav-link"
                      id="nav-vision-tab"
                      data-bs-toggle="tab"
                      data-bs-target={`#nav-vision-${id}`}
                      type="button"
                      role="tab"
                      aria-controls="nav-vision"
                      aria-selected="false"
                    >
                      Career
                    </button>
                    <button
                      className="nav-link"
                      id="nav-history-tab"
                      data-bs-toggle="tab"
                      data-bs-target={`#nav-history-${id}`}
                      type="button"
                      role="tab"
                      aria-controls="nav-history"
                      aria-selected="false"
                    >
                      Social Profiles
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id={`nav-who-${id}`}
                    role="tabpanel"
                    aria-labelledby="nav-who-tab"
                  >
                    <p>{bio}</p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id={`nav-vision-${id}`}
                    role="tabpanel"
                    aria-labelledby="nav-vision-tab"
                  >
                    <InstructorPorfolio
                      fullName={fullName}
                      portfolio={portfolio}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id={`nav-history-${id}`}
                    role="tabpanel"
                    aria-labelledby="nav-history-tab"
                  >
                    <p>You can connect with {firstName} on:</p>
                    <ul>
                      <SocialProfiles socialProfiles={socialProfiles} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstructorProfile;
