const Header = () => {
  return (
    <div className="section-title text-center">
      <span> Become a Member </span>
      <h2>Ready to Get Started?</h2>
      <p>
        After submitting the form, you will see a pop-up with the option to join
        our community on Discord, WhatsApp and Telegram. Please join Discord
        first and then select WhatsApp or Telegram.
      </p>
    </div>
  );
};

export default Header;
