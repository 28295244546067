export enum COLLECTIONS {
  MEMBERS = "members",
}

export interface ICommunityMember {
  name: string;
  phone: string;
  email: string;
  message: string;
  printed?: boolean;
}
