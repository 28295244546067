import Form from "./components/Contact/form";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeroArea from "./components/HeroArea";
import Profile from "./components/Instructor/Profile";
import WhyJoin from "./components/WhyJoin";

import { InstructorProfiles } from "./utils/instructor-profiles";

function App() {
  const renderInstructorProfiles = InstructorProfiles.map((profileDetails) => (
    <Profile key={profileDetails.id} profile={profileDetails} />
  ));

  return (
    <>
      <Header />

      <HeroArea />

      {renderInstructorProfiles}

      <WhyJoin />

      <Form />

      <Footer />
    </>
  );
}

export default App;
