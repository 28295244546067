import { firestoreDb } from "./db";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { COLLECTIONS, ICommunityMember } from "./types";

const validator = async (
  query: Query<DocumentData, DocumentData>,
  field: string
) => {
  const result = await getDocs(query);
  if (!result.empty) {
    throw new Error(`${field} has been used`);
  }
  return;
};

const membersRef = collection(firestoreDb, COLLECTIONS.MEMBERS);

const validateMember = async (
  member: Pick<ICommunityMember, "email" | "phone">
) => {
  await validator(
    query(membersRef, where("email", "==", member.email.toLowerCase())),
    "Email"
  );
  await validator(
    await query(membersRef, where("phone", "==", member.phone.toLowerCase())),
    "Phone Number"
  );
};

export const AddMember = async (member: ICommunityMember) => {
  try {
    await validateMember(member);
    await addDoc(membersRef, { ...member, printed: false });
  } catch (e: any) {
    throw new Error(`Unable to submit information ${e.message}`);
  }
};
