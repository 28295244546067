const Info = () => {
  return (
    <div className="contact-item-wrapper">
      <div className="row">
        <div className="col-12 col-md-6 col-xl-12">
          <div className="contact-item">
            <div className="contact-icon">
              <i className="lni lni-phone"></i>
            </div>
            <div className="contact-content">
              <h4>Contact</h4>
              <p>+2348087309289</p>
              <p>office@dixre.com</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-12">
          <div className="contact-item">
            <div className="contact-icon">
              <i className="lni lni-map-marker"></i>
            </div>
            <div className="contact-content">
              <h4>Address</h4>
              <p>4th Floor Suite 6D</p>
              <p>ASD City Mall</p>
              <p>No 8/9 Independence Way</p>
              <p>by Katsina Road kaduna.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-12">
          <div className="contact-item">
            <div className="contact-icon">
              <i className="lni lni-alarm-clock"></i>
            </div>
            <div className="contact-content">
              <h4>Office Days / Hours</h4>
              <p>6 Days Open (Monday - Saturday)</p>
              <p>Office time: 9 AM - 6:00 PM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
