interface ISocialProfilesProps {
  socialProfiles: { platform: string; link: string; isVisible: boolean }[];
}

const SocialProfiles: React.FC<ISocialProfilesProps> = (
  props: ISocialProfilesProps
): JSX.Element => {
  const renderSocialProfiles = props.socialProfiles.map((profile, index) => {
    const profileItem = (
      <li key={index}>
        <a href={profile.link} target="_blank" rel="noreferrer" className="btn">
          {profile.platform}
        </a>
      </li>
    );

    return profile.isVisible ? profileItem : "";
  });

  return <>{renderSocialProfiles}</>;
};

export default SocialProfiles;
