import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../Input';
import Header from '../Header';
import Info from '../Info';
import { createPortal } from 'react-dom';
import { Modal, Card, Typography } from '@mui/material';
import { useState } from 'react';
import { AddMember } from '../../../actions/join-community';
import { toast } from 'react-toastify';

interface FormValues {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const socialIcons = [
  { icon: 'discord.png', link: 'https://discord.gg/JrBVfszw' },
  {
    icon: 'whatsapp.png',
    link: 'https://chat.whatsapp.com/KaVfq7BplsiCTnfxrFvx0a',
  },
  { icon: 'telegram.png', link: 'https://t.me/dixre_community' },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
});

const ContactForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setLoading(true);
    AddMember(values)
      .then(() => {
        toast.success('Thanks for joining our community.');
        setShowModal(true);
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        toast.error(e.message);
      });
    setSubmitting(false);
  };

  return (
    <section id="get-started" className="contact-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 d-none d-xl-block">
            <Info />
          </div>
          <div className="col-xl-8">
            <div className="contact-form-wrapper">
              <div className="row">
                <div className="col-xl-10 col-lg-8 mx-auto">
                  <Header />
                </div>
              </div>
              <Formik
                initialValues={{ name: '', email: '', phone: '', message: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="contact-form">
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          component={CustomInput}
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          component={CustomInput}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          type="text"
                          name="phone"
                          placeholder="Phone Number"
                          component={CustomInput}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          type="textarea"
                          name="message"
                          placeholder="Got a question? Feel free to ask here..."
                          rows={5}
                          component={CustomInput}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="button text-center rounded-buttons">
                          <button
                            type="submit"
                            className="btn primary-btn rounded-full"
                            disabled={isLoading}
                          >
                            {isLoading ? 'Submitting...' : 'Join Our Community'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-xl-4 d-xl-none" style={{ marginTop: '30px' }}>
            <Info />
          </div>
        </div>
      </div>
      {createPortal(
        <Modal
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
            height: {
              sm: '100vh',
              xs: '100vh',
            },
          }}
          disableAutoFocus
          open={showModal}
        >
          <>
            <Card
              sx={{
                borderRadius: 5,
                margin: 1,
                width: { md: 800, xs: '90%' },
                height: { md: 500, xs: '50vh' },
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 5,
              }}
            >
              <Typography variant="h4">Welcome to our community!</Typography>
              <Typography variant="body1" style={{ marginBottom: 10 }}>
                We recommend that you join our Discord channel first as well as
                our WhatsApp or Telegram community by selecting the relevant
                icons below.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {socialIcons.map(({ icon, link }, index) => (
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      width: '30%',
                      cursor: 'pointer',
                      display: 'block',
                    }}
                  >
                    <img alt="social" src={`/icons/${icon}`} />
                  </a>
                ))}
              </div>
            </Card>
          </>
        </Modal>,
        document.body
      )}
    </section>
  );
};

export default ContactForm;
