import { menuItems } from "../../utils/menu-itmes";

const Header = () => {
  const renderMenuItems = menuItems.map((item) => (
    <li key={item.href} className="nav-item">
      <a className="page-scroll active" href={item.href}>
        {item.title}
      </a>
    </li>
  ));

  return (
    <section className="navbar-area navbar-nine">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="index.html">
                <h5>Dixre Community</h5>
              </a>

              <div
                className="collapse navbar-collapse sub-menu-bar"
                id="navbarNine"
              >
                <ul className="navbar-nav me-auto">
                  {renderMenuItems}
                  {/* <li className="nav-item">
                    <a className="page-scroll" href="#chatto">
                      Chatto
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="page-scroll" href="#solomon">
                      Solomon
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="page-scroll" href="#call-action">
                      Why Join
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="page-scroll" href="#get-started">
                      Get Started
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
