import { ResponsiveVideo, CustomIFrame } from "./default.styled";

interface IYoutubeEmbedProps {
  embedId: string;
}

const YoutubeEmbed: React.FC<IYoutubeEmbedProps> = (
  props: IYoutubeEmbedProps
): JSX.Element => {
  const { embedId } = props;

  return (
    <ResponsiveVideo>
      <CustomIFrame
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </ResponsiveVideo>
  );
};

export default YoutubeEmbed;
