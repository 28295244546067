const WhyJoin = () => {
  return (
    <section id="call-action" className="call-action">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
            <div className="inner-content">
              <h2>Why You Should Join Our Community</h2>
              <p>
                Gain free access to a wealth of industry insights, resources,
                and expert advice, stay ahead by exploring new trends, updates,
                and opportunities in the tech industry, connect with like-minded
                professionals to foster collaboration, teamwork, and
                partnerships, and elevate your skills through interactive
                webinars, workshop sessions, and discussions.
              </p>
              <div className="light-rounded-buttons">
                <a href="#get-started" className="btn primary-btn-outline">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyJoin;
