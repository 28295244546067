interface IInstructorPorfolioProps {
  fullName: string;
  portfolio: string[];
}

const InstructorPorfolio: React.FC<IInstructorPorfolioProps> = (
  props: IInstructorPorfolioProps
): JSX.Element => {
  const { fullName, portfolio } = props;

  const renderPorfolio = portfolio.map((item) => <p key={item}>{item}</p>);

  return (
    <>
      <p>
        {fullName} has worked for several companies in his career as a software
        engineer, some of which include:
      </p>

      {renderPorfolio}
    </>
  );
};

export default InstructorPorfolio;
