/* eslint-disable jsx-a11y/anchor-is-valid */
const Footer = () => {
  return (
    <>
      <footer className="footer-area footer-eleven">
        <div className="footer-top">
          <div className="container">
            <div className="inner-content">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="footer-widget f-about">
                    <p className="copyright-text">
                      © 2024 Dixre Community. Powered by&nbsp;
                      <a
                        href="https://dixre.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Dixre Ltd{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" className="scroll-top btn-hover">
        <i className="lni lni-chevron-up"></i>
      </a>
    </>
  );
};

export default Footer;
