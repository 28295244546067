import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
`;
export const ErrorMessage = styled.div`
  color: red;
  font-size: 13px;
  position: absolute;
  bottom: 5px;
  left: 15px;
`;
